import { Component } from '@angular/core';
import { AuthService } from '../../../core/services/auth.service';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
})
export class MainComponent {
    isSidebarMinimized = false;

    constructor(private readonly authService: AuthService) {}

    public isAdmin() {
        return this.authService.isAdmin;
    }

    slideContent(value: boolean) {
        this.isSidebarMinimized = value;
    }
}
