import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ButtonColors, ButtonTypes } from '@ed---interne/ng-uui-components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User, userTemplate } from 'src/app/core/models/user.model';

@Component({
    selector: 'modalUserInfo',
    templateUrl: './modal-user-info.component.html',
    styleUrls: ['./modal-user-info.component.scss'],
})
export class ModalUserInfo {
    @Input() user: User = userTemplate;

    @Input() header: string = 'Modifier mon profil';
    @Input() contentText: string = 'Êtes-vous sûr ?';
    @Input() buttonConfirmText: string = 'Valider';
    @Input() buttonConfirmVariant: string = 'info';

    @Output() onConfirm = new EventEmitter<User>();
    @Output() onUndo = new EventEmitter();

    public password: string | null = null;
    public passwordConfirm: string | null = null;
    public ButtonTypes = ButtonTypes;
    public ButtonColors = ButtonColors;
    constructor(private modalService: NgbModal) {}

    isMail(input: string): boolean {
        const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return expression.test(input);
    }

    confirm() {
        if (this.password && this.password === this.passwordConfirm) {
            this.user.password = this.password;
        }

        if (!this.password || this.password === this.passwordConfirm) {
            this.onConfirm.emit(this.user);
            this.modalService.dismissAll();
        }
    }

    undo() {
        this.onUndo.emit();
        this.modalService.dismissAll();
    }
}
