import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { AuthState, Logout } from '../../states/auth.state';
import { Observable } from 'rxjs';
import { User } from '../../models/user.model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalUserInfo } from '../modalUserInfo/modal-user-info.component';
import { UpdateUser } from 'src/app/main/states/adminUsers.state';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    projectName = 'ED Web Template';

    @Select(AuthState.loggedUser) loggedUser$!: Observable<User>;
    public loggedUser!: User;

    constructor(
        private router: Router,
        private store: Store,
        private modalService: NgbModal,
    ) {}

    ngOnInit() {
        this.loggedUser$.subscribe((data: any) => {
            return (this.loggedUser = data);
        });
    }

    getInitials(): string {
        let fullName = ' ';
        if (this.loggedUser?.firstname) fullName = `${this.loggedUser?.firstname}`;
        if (this.loggedUser?.lastname) fullName = `${fullName} ${this.loggedUser?.lastname}`;

        return fullName.split(' ').reduce((initials, word) => {
            return `${initials}${word[0]}`;
        }, ' ');
    }

    public openModalEditUser(): void {
        const modalRef = this.modalService.open(ModalUserInfo, { centered: true });

        modalRef.componentInstance.header = 'Mon profil';
        modalRef.componentInstance.user = structuredClone(this.loggedUser);
        modalRef.componentInstance.buttonConfirmText = 'Mettre à jour';

        modalRef.componentInstance.onConfirm.subscribe((userToUpdate: User) => {
            this.store.dispatch(new UpdateUser({ user: userToUpdate }));
        });
    }

    logOut(): void {
        this.store.dispatch(new Logout());
    }
}
