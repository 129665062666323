import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { User } from '../../core/models/user.model';
import { AdminUsersService } from '../services/adminUsers.service';
import { ToastService } from 'src/app/core/services/toast.service';

/** This file is divided in 3 parts:
 * - The definition of the Store : defining data structure
 * - Constants that allow NgXs to trigger Actions
 * - Selectors & Actions : Functions allowed to interact with the store
 *
 */

// Store definition
export interface AdminUsersStateModel {
    users: User[];
}

//Constants

export class GetUsers {
    static readonly type = 'ADMIN/USERS/ALL';
}
export class DeleteUser {
    static readonly type = 'ADMIN/USERS/DELETE';
    constructor(public payload: { userId: string }) {}
}
export class UpdateUser {
    static readonly type = 'ADMIN/USERS/UPDATE';
    constructor(public payload: { user: User }) {}
}

export class createUser {
    static readonly type = 'ADMIN/USERS/CREATE';
    constructor(public payload: { user: User }) {}
}

//Actions

@State<AdminUsersStateModel>({
    name: 'AdminUsers',
    defaults: {
        users: [],
    },
})
@Injectable()
export class AdminUsersState {
    @Selector()
    public static users(state: AdminUsersStateModel): User[] | null {
        return state.users;
    }

    constructor(
        private adminUsersService: AdminUsersService,
        private toastService: ToastService,
    ) {}

    @Action(GetUsers)
    public async getUsers(ctx: StateContext<AdminUsersStateModel>): Promise<void> {
        try {
            const users = await this.adminUsersService.getUsers();
            ctx.patchState({ users: users });
        } catch (err: any) {
            console.log(err);
            ctx.patchState({ users: [] });
        }
    }

    @Action(UpdateUser)
    public async updateUser(
        ctx: StateContext<AdminUsersStateModel>,
        action: UpdateUser,
    ): Promise<void> {
        try {
            const msg = await this.adminUsersService.updateUser(action.payload.user);
            this.toastService.showSuccessToast('Cet utilisateur a bien été modifié');
            ctx.dispatch(GetUsers);
        } catch (err: any) {
            this.toastService.showErrorToast(
                "une erreur est survenue : Impossible de mettre à jour l'utilisateur",
            );
            console.log(err);
        }
    }

    @Action(createUser)
    public async createUser(
        ctx: StateContext<AdminUsersStateModel>,
        action: createUser,
    ): Promise<void> {
        try {
            const msg = await this.adminUsersService.createUser(action.payload.user);
            this.toastService.showSuccessToast('Cet utilisateur a bien été créé');
            ctx.dispatch(GetUsers);
        } catch (err: any) {
            this.toastService.showErrorToast(
                "une erreur est survenue : Impossible de créer l'utilisateur",
            );
            console.log(err);
        }
    }

    @Action(DeleteUser)
    public async deleteUser(
        ctx: StateContext<AdminUsersStateModel>,
        action: DeleteUser,
    ): Promise<void> {
        try {
            const msg = await this.adminUsersService.deleteUser(action.payload.userId);
            this.toastService.showSuccessToast('Cet utilisateur a bien été supprimé');
            ctx.dispatch(GetUsers);
        } catch (err: any) {
            this.toastService.showErrorToast(
                "une erreur est survenue : Impossible de supprimer l'utilisateur",
            );
            console.log(err);
        }
    }
}
