import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Toast } from 'bootstrap';
import { fromEvent, take } from 'rxjs';
import { EventTypes } from '../../models/event-types.model';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
    @Output() disposeEvent = new EventEmitter();

    @ViewChild('toastElement', { static: true })
    toastEl!: ElementRef;

    @Input()
    type!: EventTypes;

    @Input()
    message!: string;

    toast!: Toast;

    delay: number = 5000;

    ngOnInit() {
        this.show();
    }

    show() {
        this.toast = new Toast(
            this.toastEl.nativeElement,
            this.type === EventTypes.Error
                ? {
                      autohide: false,
                  }
                : {
                      delay: this.delay,
                  },
        );

        fromEvent(this.toastEl.nativeElement, 'hidden.bs.toast')
            .pipe(take(1))
            .subscribe(() => this.hide());

        this.toast.show();
    }

    hide() {
        this.toast.dispose();
        this.disposeEvent.emit();
    }
}
