<div class="edTable">
    <div class="table-responsive">
        <table class="table table-hover">
            <caption id="edTableCaption" *ngIf="caption" >{{caption}}</caption>
            <thead class="table-light">
                <tr>
                    <th></th>
                    <th 
                        *ngFor="let header of headers; let indexOfelement=index;"
                        (click)="orderBy(header.key)"  [id]="'header_'+indexOfelement">
                        <span>{{header.label}}</span>
                        
                        <i *ngIf="sortByKey === header.key"  [id]="'sorted_'+indexOfelement"
                            class="sorted bi"
                            [ngClass]="sortOrder ? 'bi-arrow-up': 'bi-arrow-down'"
                            >
                        </i>
                        <i *ngIf="sortByKey !== header.key"
                            class="sortOption bi bi-arrow-down"
                            >
                        </i>
                         
                    </th>
                    <th *ngIf="actions.length > 0 && data !== null" id="actionHeader"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let element of data | orderBy: sortByKey; let currentLine=index;">
                    <td>
                        <input [id]="'select_'+currentLine" *ngIf="isSelectMode" type="checkbox" [checked]="isSelected(element)" (click)="onSelect(element)"/>
                    </td>
                    <td *ngFor="let h of headers;" [id]="'data_'+currentLine+'_'+h.key">{{format(element[h.key])}}</td>
                    
                    <td *ngIf="actions.length > 0"> 
                        <div ngbDropdown >
                            <div ngbDropdownToggle id="actionDropdown" class="action"  >
                                <i class="bi bi-three-dots-vertical"></i>
                            </div>
                            <div ngbDropdownMenu aria-labelledby="actionDropdown" class="position-fixed">
                                <div *ngFor="let action of actions" >
                                    <button ngbDropdownItem 
                                        [id]="'action_'+currentLine+'_'+action.label"
                                        *ngIf="checkIfHidden(action, element)"
                                        (click)="onActionClick(action, element)"
                                        [disabled]="checkIfDisabled(action, element)">{{action.label}}
                                    </button>
                                </div>
                            </div>
                        </div>

                    </td>
                    
                </tr>

                <tr id="edTableSkeleton" class="loading" *ngIf="data === null" ><td></td><td *ngFor="let h of headers"></td></tr>
                <tr class="loading" *ngIf="data === null" ><td></td><td *ngFor="let h of headers"></td></tr>
                <tr class="loading" *ngIf="data === null" ><td></td><td *ngFor="let h of headers"></td></tr>
                <tr class="loading" *ngIf="data === null" ><td></td><td *ngFor="let h of headers"></td></tr>
        
            </tbody>
        </table>
    </div>
</div>