import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
    readonly DELIMITER = '/';

    fromModel(value: string | null): NgbDateStruct | null {
        if (value) {
            const date = new Date(value);
            return {
                day: date.getDate(),
                month: date.getMonth(),
                year: date.getFullYear(),
            };
        }
        return null;
    }

    toModel(date: NgbDateStruct | null): string | null {
        if (date) {
            const newDate = new Date();
            newDate.setDate(date.day);
            newDate.setMonth(date.month);
            newDate.setFullYear(date.year);
            return newDate.toDateString();
        }
        return null;
    }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
    readonly DELIMITER = '/';

    parse(value: string): NgbDateStruct | null {
        if (value) {
            const date = new Date(value);
            return {
                day: date.getDate(),
                month: date.getMonth(),
                year: date.getFullYear(),
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        return date
            ? ('0' + date.day).slice(-2) +
                  this.DELIMITER +
                  ('0' + date.month).slice(-2) +
                  this.DELIMITER +
                  date.year
            : '';
    }
}
