<div  class="page">
    <div class="page-title">Gestion des utilisateurs</div>
    <div class="page-description">Créez, modifiez et supprimez des utilisateurs</div>

    <div class="page-content">
        <edTable
            [headers]="headersUsers"
            [data]="(users$ | async)"
            [actions]="actionsUsers">
        </edTable>

        <ed-button class="btnSecondary" (click)="openModalCreateUser()">Créer un nouvel utilisateur</ed-button>
    </div>
</div>