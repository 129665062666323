import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EventTypes } from '../models/event-types.model';
import { ToastEvent } from '../models/toast-event.model';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    toastEvents: Observable<ToastEvent>;
    private _toastEvents = new Subject<ToastEvent>();

    constructor() {
        this.toastEvents = this._toastEvents.asObservable();
    }

    /**
     * Show success toast notification.
     * @param message Toast message
     */
    showSuccessToast(message: string) {
        this._toastEvents.next({
            message,
            type: EventTypes.Success,
        });
    }

    /**
     * Show info toast notification.
     * @param message Toast message
     */
    showInfoToast(message: string) {
        this._toastEvents.next({
            message,
            type: EventTypes.Info,
        });
    }

    /**
     * Show warning toast notification.
     * @param message Toast message
     */
    showWarningToast(message: string) {
        this._toastEvents.next({
            message,
            type: EventTypes.Warning,
        });
    }

    /**
     * Show error toast notification.
     * @param message Toast message
     */
    showErrorToast(message: string) {
        this._toastEvents.next({
            message,
            type: EventTypes.Error,
        });
    }
}
