('use strict');

import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'edInput',
    templateUrl: './ed-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EdInputComponent),
            multi: true,
        },
    ],
})
export class EdInputComponent implements ControlValueAccessor {
    @Input() name = '';
    @Input() id = '';
    @Input() type: 'text' | 'number' | 'password' | 'email' | 'tel' | 'date' = 'text';
    @Input() class = '';
    @Input() style = '';
    @Input() placeholder = '';
    @Input() autocomplete = '';
    @Input() min = 0;
    @Input() max = 100;
    @Input() minlength = 0;
    @Input() isInvalid: boolean = false;
    @Input() validation: boolean = true;
    @Input() required: boolean = false;
    @Input() disabled: boolean = false;
    @Output() ngBlur = new EventEmitter();

    constructor() {}

    onChange: any = () => {};
    onTouch: any = () => {};
    val = '';

    onBlur() {
        this.onTouch();
    }

    set value(value: string) {
        if (value && this.val !== value) {
            this.val = value;
            this.onChange(value);
            this.onTouch(value);
        }
    }

    writeValue(value: string) {
        this.value = value;
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouch: any) {
        this.onTouch = onTouch;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    get aggregatedClass(): string {
        let _class = 'form-control';
        if (this.class) _class += ` ${this.class}`;
        return _class;
    }
}
