export interface User {
    id: string;
    mail: string;
    firstname: string;
    lastname: string;
    password?: string;
    isAdmin: boolean;
    isActive?: boolean;
    createdAt?: Date;
    updatedAt?: Date;
    lastConnexionAt?: Date;
}

export const userTemplate: User = {
    id: '',
    mail: '',
    firstname: '',
    lastname: '',
    password: '',
    isAdmin: false,
};
