<input
    #inputRef="ngModel"
    [type]="type"
    [min]="min"
    [max]="max"
    [disabled]="disabled"
    [required]="required"
    [minlength]="minlength"
    [(ngModel)]="val"
    (ngModelChange)="onChange($event)"
    [id]="id"
    [class]="aggregatedClass"
    [name]="name"
    [placeholder]="placeholder"
    [autocomplete]="autocomplete || 'off'"
    [style]="style"
    [ngClass]="{
        'is-valid': (inputRef.dirty || inputRef.touched) && inputRef.valid && validation,
        'is-invalid':
            isInvalid || ((inputRef.dirty || inputRef.touched) && inputRef.invalid && validation)
    }"
    (blur)="onBlur()"
/>

<span
    role="alert"
    class="invalid-feedback"
    *ngIf="inputRef.invalid && (inputRef.dirty || inputRef.touched)"
>
    <span *ngIf="inputRef.errors?.['required']">Ce champ est obligatoire.</span>
    <span *ngIf="inputRef.errors?.['minlength']">Il faut au moins {{ minlength }} caractères</span>
</span>
