<header>
    <h3><i class="pe-1 bi bi-stars"></i> {{ projectName }}</h3>
    <div class="d-flex">
      <div ngbDropdown *ngIf="loggedUser && loggedUser.id">
        <div class="profile-pic" ngbDropdownToggle id="profileDropdown">
          {{getInitials() }}
        </div>
        <div ngbDropdownMenu aria-labelledby="profileDropdown" class="clickable"  (click)="openModalEditUser()" >
                <div class="d-flex" >
                    <div class="profile-pic">{{ getInitials() }}</div>
                    <p>{{ loggedUser.firstname }} {{ loggedUser.lastname }}<br />{{ loggedUser.mail }}</p>
                </div>
                <div class="d-flex justify-content-end" >
                    <ed-button (click)="logOut()">Se déconnecter</ed-button>
                </div>
   
        </div>
      </div>
    </div>
  </header>
  