<div
  #toastElement
  class="toast fade toast-width mt-2"
  [ngClass]="type"
  role="alert"
  aria-live="assertive"
  aria-atomic="true"
>
  <div class="toast-body">
    <span class="d-flex">{{ message }}</span>
    <span class="d-flex justify-content-end"><i class="bi bi-x-lg closeButton" (click)="hide()"></i></span>
  </div>
</div>