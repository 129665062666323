import { CommonModule } from '@angular/common';
import { outputAst } from '@angular/compiler';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDropdownModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgPipesModule } from 'ngx-pipes';

interface Header {
    key: string;
    label: string;
}

interface DataElement {
    [x: string]: any;
}

interface Action {
    label: string;
    onClick: Function;
    disable?: Function;
    hide?: Function;
}

@Component({
    selector: 'edTable',
    templateUrl: './ed-table.component.html',
    styleUrls: ['./ed-table.component.scss'],
    standalone: true,
    imports: [CommonModule, NgPipesModule, NgbDropdownModule],
})
export class EdTableComponent {
    @Input() headers: Header[] = [];
    @Input() data: DataElement[] | null = null;
    @Input() caption = ' ';
    @Input() actions: Action[] = [];
    @Input() isSelectMode: boolean = false;

    @Output() onSelectChange = new EventEmitter();
    @Output() onClickSort = new EventEmitter();

    public sortOrder: boolean = false;
    public sortByKey: string | null = null;
    public dataSelected: DataElement[] = [];

    orderBy(key: string) {
        if (this.sortByKey === key) {
            this.sortOrder = !this.sortOrder;
        } else {
            this.sortOrder = false;
        }

        this.sortByKey = key;
        this.onClickSort.emit((this.sortByKey, this.sortOrder));
    }

    private isDate(_date: any) {
        const _regExp = new RegExp(
            '^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$',
        );
        return _regExp.test(_date);
    }

    format(value: any): any {
        if (typeof value === 'boolean') {
            return value ? 'Oui' : 'Non';
        }
        if (this.isDate(value)) {
            return new Date(value).toLocaleString();
        } else {
            return value;
        }
    }

    checkIfDisabled(action: Action, element: any): boolean {
        if (action.disable) {
            return action.disable(element);
        } else return false;
    }

    checkIfHidden(action: Action, element: any): boolean {
        if (action.hide) {
            return !action.hide(element);
        } else return true;
    }

    isSelected(elem: DataElement) {
        return this.dataSelected.find((d) => d === elem);
    }

    onSelect(elem: DataElement) {
        if (this.isSelected(elem)) {
            this.dataSelected = this.dataSelected.filter((d) => d !== elem);
        } else {
            this.dataSelected = [...this.dataSelected, elem];
        }
        this.onSelectChange.emit(this.dataSelected);
    }

    onActionClick(action: Action, elem: DataElement) {
        if ((action?.disable && !action.disable(elem)) || !action.disable) {
            action.onClick(elem);
        }
    }
}
