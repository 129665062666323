import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ErrorHttp } from '../../../core/models/errorHttp.model';
import { AuthState, Login } from '../../../core/states/auth.state';
import { ButtonTypes } from '@ed---interne/ng-uui-components';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
    email = '';
    password = '';
    errorMessage = '';
    //Read error from store
    @Select(AuthState.error) error$: Observable<ErrorHttp> | undefined;

    public ButtonTypes = ButtonTypes;

    constructor(private store: Store) {}

    public async login(loginForm: any) {
        this.store.dispatch(
            new Login({
                email: this.email,
                password: this.password,
            }),
        );
    }
}
