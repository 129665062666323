<form class="modalUserInfo"             
    #userForm="ngForm"
    (ngSubmit)="confirm()"
    >
    <div class="modal-header"> {{header}} </div>

    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col col-xs-6"> 
                    <label for="email" class="form-label">Email</label>
                </div>
                <div class="col col-xs-6">
                    <edInput
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Email"
                        [required]="true"
                        [(ngModel)]="user.mail"
                        [isInvalid]="!isMail(user.mail)"
                    ></edInput>
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-6"> 
                    <label for="firstname" class="form-label">Prénom</label>
                </div>
                <div class="col col-xs-6">
                    <edInput
                        id="firstname"
                        name="firstname"
                        type="text"
                        placeholder="Prénom"
                        [required]="true"
                        [(ngModel)]="user.firstname"
                        [isInvalid]="!user.firstname"
                    ></edInput>
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-6"> 
                    <label for="Name" class="form-label">Nom</label>
                </div>
                <div class="col col-xs-6">
                    <edInput
                        id="lastname"
                        name="lastname"
                        type="text"
                        placeholder="Nom de famille"
                        [required]="true"
                        [(ngModel)]="user.lastname"
                        [isInvalid]="!user.lastname"
                    ></edInput>
                </div>
            </div>
                <div class="row">
                    <div class="col col-xs-6"> 
                        <label for="password" class="form-label">Mot de passe</label>
                    </div>
                    <div class="col col-xs-6">
                        <edInput
                            id="mdp"
                            name="Mot de passe"
                            type="password"
                            placeholder="Mot de passe"
                            [minlength]="7"
                            [isInvalid]="(!!password && password.length < 7)"
                            [(ngModel)]="password"
                        ></edInput>
                    </div>
                </div>
                <div class="row" *ngIf="password !== null">
                    <div class="col col-xs-6"> 
                        <label for="mdp_confirm" class="form-label">Confirmez le mot de passe</label>
                    </div>
                    <div class="col col-xs-6">
                        <edInput
                            id="mdp_confirm"
                            name="Confirmation"
                            type="password"
                            [required]="true"
                            placeholder="Confirmation"
                            [minlength]="7"
                            [isInvalid]="(!!password && password !== passwordConfirm)"
                            [(ngModel)]="passwordConfirm"
                        ></edInput>
                    </div>
                </div>
            <div class="row">
                <div class="col col-xs-6">Administrateur</div>
                <div class="col col-xs-6">
                    <input type="checkbox" [(ngModel)]="user.isAdmin" [ngModelOptions]="{standalone: true}" class="form-check-input"/>
                </div>
            </div>
        </div>

    </div>

    <div class="modal-footer">
        <ed-button [type]="ButtonTypes.Button" [colorSchema]="ButtonColors.Secondary" (click)="undo()">Quitter</ed-button>
        <ed-button [type]="ButtonTypes.Submit" [colorSchema]="ButtonColors.Primary"  [disabled]="!userForm.form.valid">{{buttonConfirmText}}</ed-button>
    </div>
</form>