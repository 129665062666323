import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'EdModalConfirm',
    templateUrl: './ed-modal-confirm.component.html',
    styleUrls: ['./ed-modal-confirm.component.scss'],
    standalone: true,
    imports: [CommonModule, NgbModule],
})
export class EdModalConfirm {
    @Input() header: string = 'Confirmation';

    @Input() contentText: string = 'Êtes-vous sûr ?';
    @Input() buttonConfirmText: string = 'Valider';
    @Input() buttonConfirmVariant: string = 'info';
    @Input() buttonUndoText = 'Annuler';

    @Output() onConfirm = new EventEmitter();
    @Output() onUndo = new EventEmitter();

    constructor(private modalService: NgbModal) {}

    confirm() {
        this.onConfirm.emit();
        this.modalService.dismissAll();
    }

    undo() {
        this.onUndo.emit();
        this.modalService.dismissAll();
    }
}
