import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './controllers/main/main.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './controllers/home/home.component';
import { StatsComponent } from './controllers/stats/stats.component';
import { ContactComponent } from './controllers/contact/contact.component';
import { RouterModule } from '@angular/router';
import { UsersComponent } from './controllers/admin/users/users.component';
import { SharedModule } from '../shared/shared.module';
import { ModalUserInfo } from '../core/components/modalUserInfo/modal-user-info.component';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '@ed---interne/ng-uui-components';

@NgModule({
    declarations: [
        MainComponent,
        SidebarComponent,
        ModalUserInfo,
        HomeComponent,
        StatsComponent,
        ContactComponent,
        UsersComponent,
    ],
    imports: [CommonModule, NgbModule, RouterModule, FormsModule, SharedModule, ComponentsModule],
})
export class MainModule {}
