import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import { AuthState } from 'src/app/core/states/auth.state';
import { ModalUserInfo } from 'src/app/core/components/modalUserInfo/modal-user-info.component';
import {
    AdminUsersState,
    DeleteUser,
    GetUsers,
    UpdateUser,
    createUser,
} from 'src/app/main/states/adminUsers.state';
import { EdModalConfirm } from 'src/app/shared/components/modals/ed-modal-confirm/ed-modal-confirm.component';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
    @Select(AdminUsersState.users) users$: Observable<User[]> | undefined;
    @Select(AuthState.loggedUser) loggedUser$!: Observable<User>;
    private loggedUser: User | undefined;

    headersUsers = [
        { key: 'id', label: 'Identifiant' },
        { key: 'firstname', label: 'Prénom' },
        { key: 'lastname', label: 'Nom' },
        { key: 'mail', label: 'Email' },
        { key: 'createdAt', label: "Date d'inscription" },
        { key: 'lastConnexionAt', label: 'Dernière visite' },
        { key: 'isAdmin', label: 'Administrateur' },
        { key: 'isActive', label: 'Compte actif' },
    ];

    actionsUsers = [
        {
            label: 'Activer',
            onClick: (elem: User) => {
                this.activateUser(elem);
            },
            hide: (elem: User) => {
                return elem.isActive;
            },
        },
        {
            label: 'Désactiver',
            onClick: (elem: User) => {
                this.deActivateUser(elem);
            },
            hide: (elem: User) => {
                return !elem.isActive;
            },
            disable: (elem: User): boolean => {
                return this.loggedUser?.id === elem.id;
            },
        },
        {
            label: 'Modifier',
            onClick: (elem: User) => {
                this.openModalUpdateUser(elem);
            },
        },
        {
            label: 'Supprimer',
            onClick: (elem: User) => {
                this.deleteUser(elem);
            },
            disable: (elem: User): boolean => {
                return this.loggedUser?.id === elem.id;
            },
        },
    ];

    constructor(
        private store: Store,
        private modalService: NgbModal,
    ) {}

    ngOnInit() {
        this.store.dispatch(new GetUsers());
        this.loggedUser$.subscribe((data: any) => {
            return (this.loggedUser = data);
        });
    }

    public deleteUser(user: User): void {
        const modalRef = this.modalService.open(EdModalConfirm, { centered: true });

        modalRef.componentInstance.contentText = `Voulez-vous supprimer définitivement l'utilisateur ${user.mail}?`;
        modalRef.componentInstance.buttonConfirmText = 'Supprimer';
        modalRef.componentInstance.buttonConfirmVariant = 'danger';
        modalRef.componentInstance.buttonUndoText = 'Annuler';

        modalRef.componentInstance.onConfirm.subscribe(() => {
            this.store.dispatch(new DeleteUser({ userId: user.id }));
        });
    }

    public activateUser(user: User): void {
        const modalRef = this.modalService.open(EdModalConfirm, { centered: true });

        modalRef.componentInstance.contentText = `Voulez-vous activer l'utilisateur ${user.mail}?`;
        modalRef.componentInstance.buttonConfirmText = 'Activer';
        modalRef.componentInstance.buttonUndoText = 'Annuler';

        modalRef.componentInstance.onConfirm.subscribe(() => {
            this.store.dispatch(new UpdateUser({ user: { ...user, isActive: true } }));
        });
    }

    public deActivateUser(user: User): void {
        const modalRef = this.modalService.open(EdModalConfirm, { centered: true });

        modalRef.componentInstance.contentText = `Voulez-vous désactiver l'utilisateur ${user.mail}?`;
        modalRef.componentInstance.buttonConfirmText = 'Activer';
        modalRef.componentInstance.buttonUndoText = 'Annuler';

        modalRef.componentInstance.onConfirm.subscribe(() => {
            this.store.dispatch(new UpdateUser({ user: { ...user, isActive: false } }));
        });
    }

    public openModalUpdateUser(user: User): void {
        const modalRef = this.modalService.open(ModalUserInfo, { centered: true });

        modalRef.componentInstance.header = "Mettre à jour l'utilisateur";
        modalRef.componentInstance.user = structuredClone(user);
        modalRef.componentInstance.buttonConfirmText = 'Enregistrer';

        modalRef.componentInstance.onConfirm.subscribe((userToUpdate: User) => {
            this.store.dispatch(new UpdateUser({ user: userToUpdate }));
        });
    }

    public openModalCreateUser(): void {
        const modalRef = this.modalService.open(ModalUserInfo, { centered: true });

        modalRef.componentInstance.header = 'Créer un nouvel utilisateur';
        modalRef.componentInstance.buttonConfirmText = 'Enregistrer';

        modalRef.componentInstance.onConfirm.subscribe((user: User) => {
            this.store.dispatch(new createUser({ user: user }));
        });
    }
}
