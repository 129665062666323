<div class="d-flex" id="sideBar" [ngClass]="{ minimized: minimized }">
    <ul ngbNav #nav class="flex-column">
        <div class="sidebar-toggle">
            <i *ngIf="!minimized" class="bi bi-chevron-left" (click)="toggleSidebar()"></i>
            <i *ngIf="minimized" class="bi bi-chevron-right" (click)="toggleSidebar()"></i>
        </div>
        <div *ngFor="let item of menuSideBarTop">
            <li *ngIf="(!item.isAdmin || isAdmin)" >
                <div ngbNavItem *ngIf="item.subItems.length === 0"  [routerLink]="item.routerLink" routerLinkActive="active">
                    <i class="bi mr-3" [ngClass]="item.iconClass"></i>
                    <span class="menu-text">{{item.name}}</span>
                </div>
                <div  *ngIf="item.subItems.length > 0">
                    <div ngbNavItem [routerLink]="item.routerLink">
                        <i class="bi mr-3" [ngClass]="item.iconClass"></i>
                        <span class="menu-text">{{item.name}}</span>
                    </div>
                    <div *ngFor="let subItem of item.subItems">
                        <ul #nav *ngIf="(!subItem.isAdmin || isAdmin)" >
                            <li ngbNav class="subItem" ngbNavItem [routerLink]="subItem.routerLink" routerLinkActive="active">
                                <span class="menu-text">{{subItem.name}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
        </div>
       
        
        <div class="bottom-sidebar">
            <div *ngFor="let item of menuSideBarBottom">
                <li *ngIf="(!item.isAdmin || isAdmin)" >
                    <div ngbNavItem *ngIf="item.subItems.length === 0"  [routerLink]="item.routerLink" routerLinkActive="active">
                        <i class="bi mr-3" [ngClass]="item.iconClass"></i>
                        <span class="menu-text">{{item.name}}</span>
                    </div>
                    <div  *ngIf="item.subItems.length > 0">
                        <div ngbNavItem [routerLink]="item.routerLink">
                            <i class="bi mr-3" [ngClass]="item.iconClass"></i>
                            <span class="menu-text">{{item.name}}</span>
                        </div>
                        <div *ngFor="let subItem of item.subItems">
                            <ul #nav *ngIf="(!subItem.isAdmin || isAdmin)" >
                                <li ngbNav class="subItem" ngbNavItem [routerLink]="subItem.routerLink" routerLinkActive="active">
                                    <span class="menu-text">{{subItem.name}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
            </div>
        </div>
    </ul>
</div>
