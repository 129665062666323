import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './controllers/login/login.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '@ed---interne/ng-uui-components';

@NgModule({
    declarations: [LoginComponent],
    imports: [CommonModule, SharedModule, FormsModule, ComponentsModule],
})
export class LoginModule {}
