import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { AuthService } from './services/auth.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './services/jwt.interceptor';
import { ToastComponent } from './components/toast/toast.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { ToastService } from './services/toast.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { NgxsModule } from '@ngxs/store';
import { ComponentsModule } from '@ed---interne/ng-uui-components';
@NgModule({
    declarations: [HeaderComponent, ToastComponent, ToasterComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        NgbModule,
        SharedModule,
        NgxsModule,
        ComponentsModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        AuthService,
        ToastService,
    ],
    exports: [HeaderComponent, ToastComponent, ToasterComponent],
})
export class CoreModule {}
