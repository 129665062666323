import { Observable, EMPTY, from } from 'rxjs';
import { AuthService } from './core/services/auth.service';
import { User } from './core/models/user.model';
export function initializeAppFactory(authService: AuthService): () => Observable<User> {
    return () => {
        let token = localStorage.getItem('Authorization');
        if (token) {
            token = token.slice(7); //Remove 'Bearer '
            return from(authService.registerToken(token));
        } else {
            return EMPTY;
        }
    };
}
