import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('Authorization');
        const isApiRequest = httpRequest.url.includes(environment.apiUrl);

        if (token && isApiRequest) {
            httpRequest = httpRequest.clone({
                setHeaders: {
                    Authorization: `${token}`,
                },
            });
        }
        return next.handle(httpRequest);
    }
}
