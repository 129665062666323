<div class="page">
    <div class="page-title">Page d'accueil</div>
    <div class="page-description">Bienvenue !</div>

    <div class="page-content">
        <p>home works!</p>
        <ed-button (click)="throwTestError()">Test Sentry Error</ed-button>

    </div>
</div>

