import { Inject, Injectable } from '@angular/core';
import { RestService } from '../../core/services/rest.service';
import { User } from '../../core/models/user.model';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AdminUsersService {
    private _users = false;

    constructor(
        @Inject(RestService) private restService: RestService,
        private store: Store,
        private router: Router,
    ) {}

    public get users(): boolean {
        return this._users;
    }

    public async getUsers(): Promise<User[]> {
        const path = '/user/all';
        const users = await this.restService.get(path, {});
        return users;
    }

    public async deleteUser(userId: string): Promise<void> {
        const path = `/user/${userId}`;
        await this.restService.delete(path, {});
    }

    public async updateUser(user: User): Promise<void> {
        const path = `/user`;
        await this.restService.put(path, {}, user);
    }

    public async createUser(user: User): Promise<void> {
        const path = `/user`;
        await this.restService.post(path, {}, user);
    }
}
