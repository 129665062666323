import { Component, EventEmitter, Input, Output } from '@angular/core';

interface item {
    routerLink: string;
    iconClass: string;
    name: string;
    isAdmin: boolean;
    subItems: item[];
}

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
    @Output() onMinimized = new EventEmitter<boolean>();
    @Input() isAdmin: boolean = false;

    minimized = false;

    menuSideBarTop: item[] = [
        {
            routerLink: '/main',
            iconClass: 'bi-house',
            name: 'Accueil',
            isAdmin: false,
            subItems: [],
        },
        {
            routerLink: '/main/stats',
            iconClass: 'bi-bar-chart-line',
            name: 'Stats',
            isAdmin: false,
            subItems: [],
        },
        {
            routerLink: '/main/contact',
            iconClass: 'bi-envelope',
            name: 'Contact',
            isAdmin: false,
            subItems: [],
        },
    ];

    menuSideBarBottom = [
        {
            routerLink: '/admin/users',
            iconClass: 'bi-house-gear',
            name: 'Administration',
            isAdmin: true,
            subItems: [
                {
                    routerLink: '/admin/users',
                    name: 'Utilisateurs',
                    isAdmin: true,
                },
            ],
        },
    ];

    toggleSidebar() {
        this.minimized = !this.minimized;
        this.onMinimized.emit(this.minimized);
    }
}
